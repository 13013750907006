import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full font-bold text-black" }
const _hoisted_2 = { class: "text-black text-xs w-full flex justify-between" }
const _hoisted_3 = { class: "text-black" }
const _hoisted_4 = { class: "w-full text-black" }
const _hoisted_5 = { class: "text-sm text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_ellipsis = _resolveDirective("ellipsis")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex items-center text-sm ',
      !$setup.modePreview ? 'space-x-2' : 'space-x-1',
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.styles['item-order']])
    }, [
      _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_1, _cache[0] || (_cache[0] = [
        _createTextVNode(" NUMBER ")
      ]))), [
        [_directive_ellipsis, { placement: 'bottom-right' }]
      ]),
      _createElementVNode("p", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.item.ReferenceNumber ? $setup.item.ReferenceNumber : "-"), 1)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.styles['item-order']])
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-black text-xs" }, "Facility Name", -1)),
      _createElementVNode("p", {
        class: _normalizeClass(['w-36 text-black truncate', _ctx.styles['hover-text']])
      }, _toDisplayString($setup.item.Facility), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([!$setup.modePreview ? 'w-36' : 'w-28', _ctx.styles['item-order']])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-black text-xs" }, "Quantity", -1)),
      _createElementVNode("p", _hoisted_4, _toDisplayString($setup.amount + " Mwh"), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([!$setup.modePreview ? 'w-36' : 'w-48', _ctx.styles['item-order']])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-black text-xs" }, "Vintage", -1)),
      _createElementVNode("p", _hoisted_5, _toDisplayString($setup.item.Vintage), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([!$setup.modePreview ? 'w-36' : 'w-48', _ctx.styles['item-order']]),
      style: {"border-color":"black"}
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-black text-xs" }, "Location", -1)),
      _createElementVNode("p", {
        class: _normalizeClass(['font-bold text-black', _ctx.styles['text-price']])
      }, _toDisplayString($setup.item.Country), 3)
    ], 2)
  ], 2))
}