import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mb-5 justify-between" }
const _hoisted_2 = { class: "space-y-3" }
const _hoisted_3 = { class: "text-gray-400" }
const _hoisted_4 = { class: "text-gray-400" }
const _hoisted_5 = { class: "flex justify-end items-center space-x-4" }
const _hoisted_6 = ["disable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "custom-class": "title-color 2xl:w-3/5  xl:w-4/5",
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose,
    "show-close": false,
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Form"], {
        as: "el-form",
        "validation-schema": $setup.schema,
        onSubmit: $setup.onSubmit
      }, {
        default: _withCtx(({ isSubmitting }) => [
          _createElementVNode("div", {
            class: _normalizeClass(
          $setup.isLoadingRetireRECs || isSubmitting ? 'isSubmitting-rounded' : ''
        )
          }, [
            _createElementVNode("div", _hoisted_1, [
              _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "2xl:text-xl xl:text-lg font-semibold" }, " I am retiring these RECs for the months of: ", -1)),
              _createVNode($setup["Field"], { name: "MonthRange" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_date_picker, _mergeProps({
                        "model-value": value,
                        type: "monthrange"
                      }, field, {
                        editable: false,
                        clearable: false,
                        "range-separator": "To",
                        "start-placeholder": "Start month",
                        "end-placeholder": "End month",
                        "disabled-date": $setup.disabledDate,
                        onChange: (value) => $setup.handleChangeMonthRange(value),
                        format: "MM/YYYY"
                      }), null, 16, ["model-value", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["TheItemRetire"], {
                item: $setup.initialValues,
                modePreview: true
              }, null, 8, ["item"])
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
            _createElementVNode("p", _hoisted_3, " Total RECs to be distributed: " + _toDisplayString($setup.initialValues.Amount), 1),
            _createElementVNode("p", _hoisted_4, " Average Renewable Energy Per Month: " + _toDisplayString($setup.averageRenewable), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                type: "reset",
                class: "btn btn--black-primary",
                onClick: $setup.handleClose
              }, " No, go back "),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn--green-primary",
                disable: isSubmitting
              }, " Yes ", 8, _hoisted_6)
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["validation-schema"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}