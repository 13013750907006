import { defineComponent as _defineComponent } from 'vue'
import { IRenewableParameters } from "@/models/RenewableOptions";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import MetadataModule from "@/store/modules/Metadata";
import { getYear } from "@/utils/helpers/convertDateTime";
import { BuyOrderStatus } from "@ems/constants/rehashStatus";
import DialogRetireRECs from "@ems/containers/RenewableOptions/TheDialog/RetireRECs.vue";
import DialogModule from "@/store/UI/Dialog";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import AccountModule from "@/store/modules/Account";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const STATUS_RENEWABLE = {
  RETIRED: "Retired",
  UNRETIRED: "UnRetired",
};
const TOOLTIP_DATA = {
  INFORMATION_STATUS_REC: `"Retiring" a REC means that you retain ownership of it forever; it cannot be sold to someone else. By Retiring REC, you can then claim renewable energy usage in your sustainability reporting. `,
};
const router = useRouter();
const route = useRoute();
const renewAbleOptions = computed(
  () => RenewableOptionsModule.renewAbleOptionsGetter
);

const valueFacilities = ref("");
const valueYear = ref("");
const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const isLoadingRenewAbleOptions = computed(
  () => RenewableOptionsModule.isLoadingRenewAbleOptionGetter
);
const filterStatus = (value: string, row: any) => row.Status === value;
const facilities = computed(() => {
  const data = [...MetadataModule.dataFacilitiesGetter];
  data.unshift({
    CountryId: "",
    CountryName: "",
    CountryCode: "",
    Red: false,
    Url: "",
    Latitude: 0,
    Longitude: 0,
    Value: null,
    Name: "All",
  } as any);
  return data;
});

watch(
  () => AccountRehashClient.value,
  () => {
    getDataRenewableEnergy(valueFacilities.value, valueYear.value);
  }
);
onMounted(() => {
  if (AccountRehashClient.value.ExternalId) {
    getDataRenewableEnergy(valueFacilities.value, valueYear.value);
  }
});
const handleChangeFacility = (value: any) => {
  valueFacilities.value = value;
  getDataRenewableEnergy(value, valueYear.value);
};
const handleChangeYear = (value: any) => {
  if (!value) {
    getDataRenewableEnergy(valueFacilities.value, "");
    return;
  }
  valueYear.value = getYear(value).toString();
  getDataRenewableEnergy(valueFacilities.value, valueYear.value);
};

const getDataRenewableEnergy = (facilities: string, year?: string) => {
  RenewableOptionsModule.getRenewableOptionsAction({
    FacilityId: facilities,
    Year: year,
    AccountId: AccountRehashClient.value.ExternalId,
  } as IRenewableParameters);
};
const isLoadingDownLoad = computed(
  () => RenewableOptionsModule.isLoadingDownLoadGetter
);
const exportExcel = async () => {
  RenewableOptionsModule.setIsLoadingDownload(true);
  const data =
    (await RenewableOptionsModule.downloadRenewableOptionsAction()) as any;
  // eslint-disable-next-line no-undef
  const xlsx = XLSX;

  /* flatten objects */
  const rows = data.map((row: any) => ({
    RecId: row.SerialNumber,
    Vintage: row.Vintage,
    Registry: row.Registry,
    Type: row.EnergyValue,
  }));

  /* generate worksheet and workbook */
  const worksheet = xlsx.utils.json_to_sheet(rows);
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, "Dates");

  /* fix headers */
  xlsx.utils.sheet_add_aoa(
    worksheet,
    [["Rec Id", "Vintage", "Registry", "Type"]],
    {
      origin: "A1",
    }
  );

  /* calculate column width */
  const max_width = rows.reduce(
    (w: any, r: any) => Math.max(w, r.SerialNumber?.length),
    200
  );

  worksheet["!cols"] = [{ wch: max_width }];

  /* create an XLSX file and try to save to Presidents.xlsx */
  xlsx.writeFile(workbook, `ReportRec-${Date.now()}.xlsx`);
  RenewableOptionsModule.setIsLoadingDownload(false);
};
const openRetireDialog = (idx: number, item: any) => {
  DialogModule.showModal({ component: DialogRetireRECs, data: item });
};
const handleDetailRenewableOption = (_: number, row: any) => {
  router.push(`${route.path}/${row.Id}/detais`);
};

const __returned__ = { STATUS_RENEWABLE, TOOLTIP_DATA, router, route, renewAbleOptions, valueFacilities, valueYear, AccountRehashClient, isLoadingRenewAbleOptions, filterStatus, facilities, handleChangeFacility, handleChangeYear, getDataRenewableEnergy, isLoadingDownLoad, exportExcel, openRetireDialog, handleDetailRenewableOption, get IRenewableParameters() { return IRenewableParameters }, get RenewableOptionsModule() { return RenewableOptionsModule }, computed, onMounted, onUnmounted, ref, watch, get useRouter() { return useRouter }, get useRoute() { return useRoute }, get MetadataModule() { return MetadataModule }, get getYear() { return getYear }, get BuyOrderStatus() { return BuyOrderStatus }, DialogRetireRECs, get DialogModule() { return DialogModule }, BaseSvgIcon, BaseSpinner, get AccountModule() { return AccountModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})