import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "mb-3 flex justify-end items-center space-x-3" }
const _hoisted_5 = { class: "box py-5 relative" }
const _hoisted_6 = { class: "mb-3 flex justify-between justify-items-center gap-4" }
const _hoisted_7 = { class: "flex w-2/3 space-x-5" }
const _hoisted_8 = { class: "w-36" }
const _hoisted_9 = { class: "cursor-pointer group hover:text-blue-500 text-2xl mr-1" }
const _hoisted_10 = { class: "flex items-center space-x-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "underline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isLoadingDownLoad)
      ? (_openBlock(), _createBlock($setup["BaseSpinner"], {
          key: 0,
          fullScreen: ""
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ol", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: "" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Renewable Options")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--green-primary",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.router.push('/renewable-options/buy-recs')), ["prevent"]))
        }, " Buy RECs ")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex space-x-5" }, [
            _createElementVNode("h1", { class: "text-left text-lg font-semibold whitespace-nowrap" }, " Renewable Energy Attributes ")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_el_select, {
              "model-value": $setup.valueFacilities,
              placeholder: "All",
              onChange: _cache[1] || (_cache[1] = (value) => $setup.handleChangeFacility(value))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.facilities, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.Value,
                    label: item.Name,
                    value: item.Value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["model-value"]),
            _createVNode(_component_el_date_picker, {
              customClass: "xl:w-52",
              modelValue: $setup.valueYear,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.valueYear) = $event)),
              type: "year",
              editable: false,
              "disabled-date": (time) => time.getTime() > Date.now(),
              onChange: $setup.handleChangeYear,
              placeholder: "Pick a year"
            }, null, 8, ["modelValue", "disabled-date"])
          ])
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          class: "w-full table-custom",
          style: { borderRadius: '4px' },
          data: $setup.renewAbleOptions,
          height: "66vh",
          stripe: "",
          "cell-class-name": "text-cafe-dark",
          "header-row-class-name": "tableHeaderRow",
          "row-class-name": "tableRow",
          "element-loading-text": "Loading...",
          "element-loading-background": "rgba(0, 0, 0, 0.6)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              "min-width": 100,
              prop: "ReferenceNumber",
              label: "No",
              sortable: ""
            }),
            _createVNode(_component_el_table_column, {
              prop: "Facility",
              label: "Facility"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Vintage",
              sortable: "",
              label: "Vintage"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Amount",
              sortable: "",
              label: "Amount"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.Amount + " Mwh"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "Source",
              sortable: "",
              label: "Source"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope?.row?.StatusBuyOrderDescription || ""), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "Status",
              filters: [
            { text: 'Retired', value: $setup.STATUS_RENEWABLE.RETIRED },
            {
              text: 'Unretired',
              value: $setup.STATUS_RENEWABLE.UNRETIRED,
            },
          ],
              "filter-method": $setup.filterStatus
            }, {
              header: _withCtx(() => [
                _createVNode(_component_el_tooltip, {
                  effect: "light",
                  placement: "bottom"
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("p", _hoisted_8, _toDisplayString($setup.TOOLTIP_DATA.INFORMATION_STATUS_REC), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("i", _hoisted_9, [
                      _createVNode($setup["BaseSvgIcon"], {
                        class: "inline-block w-4 h-4",
                        name: "infoIcon"
                      })
                    ])
                  ]),
                  _: 1
                }),
                _cache[5] || (_cache[5] = _createElementVNode("span", null, "Status", -1))
              ]),
              _: 1
            }, 8, ["filters"]),
            _createVNode(_component_el_table_column, {
              prop: "Action",
              label: "Action"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_10, [
                  (scope.row.Status === $setup.STATUS_RENEWABLE.RETIRED)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        onClick: _withModifiers(($event: any) => (
                  $setup.handleDetailRenewableOption(scope.$index, scope.row)
                ), ["prevent"])
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("p", { class: "underline" }, "View Details", -1)
                      ]), 8, _hoisted_11))
                    : _createCommentVNode("", true),
                  (
                  scope.row.Status === $setup.STATUS_RENEWABLE.UNRETIRED &&
                  (scope.row.StatusBuyOrder === $setup.BuyOrderStatus.Completed ||
                    scope.row.StatusBuyOrder === $setup.BuyOrderStatus.Paid)
                )
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        type: "button",
                        onClick: _withModifiers(($event: any) => ($setup.openRetireDialog(scope.$index, scope.row)), ["prevent"])
                      }, [
                        _createElementVNode("p", _hoisted_13, _toDisplayString($setup.STATUS_RENEWABLE.RETIRED), 1)
                      ], 8, _hoisted_12))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])), [
          [_directive_loading, $setup.isLoadingRenewAbleOptions]
        ])
      ])
    ])
  ], 64))
}