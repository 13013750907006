import { defineComponent as _defineComponent } from 'vue'
import { IRenewableDTO } from "@/models/RenewableOptions";
import { ref, toRefs, reactive } from "vue";
import { formatNumber } from "@/utils/helpers/buildDataFormat";

interface Props {
  item: IRenewableDTO;
  modePreview?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheItemRetire',
  props: {
    item: {},
    modePreview: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { modePreview } = reactive(props);
const { item } = toRefs(props);

const amount = ref(item.value.Amount);

const __returned__ = { props, modePreview, item, amount, get IRenewableDTO() { return IRenewableDTO }, ref, toRefs, reactive, get formatNumber() { return formatNumber } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})