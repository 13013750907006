import { defineComponent as _defineComponent } from 'vue'
import { IRetireRECs } from "@/models/Retire";
import DialogModule from "@/store/UI/Dialog";
import { formatNumber } from "@/utils/helpers/buildDataFormat";
import {
  endDateOfMonth,
  startDateOfMonth,
} from "@/utils/helpers/convertDateTime";
import dayjs from "dayjs";
import { Field, Form } from "vee-validate";
import { computed, reactive, ref } from "vue";
import { array, object } from "yup";
import RetireModule from "../RetireRECs.module";
import TheItemRetire from "../TheSummary/TheItemRetire.vue";
import RenewableOptionsModule from "@ems/containers/RenewableOptions/RenewableOptions.module";
import AccountModule from "@/store/modules/Account";
import { IRenewableParameters } from "@/models/RenewableOptions";

export default /*@__PURE__*/_defineComponent({
  __name: 'RetireRECs',
  setup(__props, { expose: __expose }) {
  __expose();

const initialValues: any = DialogModule.getData;
const AccountRehashClient = computed(() => AccountModule.AccountClientRehash);
const collectData = reactive<any>({
  data: {
    FacilityId: initialValues.FacilityId,
    FromDate: "",
    ToDate: "",
    BuyOrderId: initialValues.ReferenceNumber,
    Quantity: initialValues.Amount,
  },
});

const isLoadingRetireRECs = computed(
  () => RetireModule.isLoadingRetireRECsGetter
);

const averageRenewable = computed(() => {
  return formatNumber(initialValues.Amount / (amountMonth.value + 1), 2);
});

const schema = object({
  MonthRange: array().required().nullable().label("MonthRange"),
});

const amountMonth = ref(1);

const handleClose = () => {
  DialogModule.hideModal();
};
const disabledDate = (time: Date) => {
  return (
    time.getTime() > Date.now() ||
    time.getTime() < dayjs().subtract(12, "months").toDate().getTime()
  );
};

const onSubmit = async (values: any) => {
  const data = {
    ...collectData.data,
    FromDate: startDateOfMonth(values.MonthRange[0]),
    ToDate: endDateOfMonth(values.MonthRange[1]),
  } as IRetireRECs;
  await RetireModule.retireRECs(data);
  RenewableOptionsModule.getRenewableOptionsAction({
    AccountId: AccountRehashClient.value.ExternalId,
  } as IRenewableParameters);

  handleClose();
};

const handleChangeMonthRange = (value: any) => {
  if (!value) return;
  if (dayjs(value[0]).year() === dayjs(value[1]).year()) {
    amountMonth.value = dayjs(value[1]).month() - dayjs(value[0]).month();
  } else {
    amountMonth.value =
      dayjs(value[1]).month() + (12 - dayjs(value[0]).month());
  }
};

const __returned__ = { initialValues, AccountRehashClient, collectData, isLoadingRetireRECs, averageRenewable, schema, amountMonth, handleClose, disabledDate, onSubmit, handleChangeMonthRange, get IRetireRECs() { return IRetireRECs }, get DialogModule() { return DialogModule }, get formatNumber() { return formatNumber }, get endDateOfMonth() { return endDateOfMonth }, get startDateOfMonth() { return startDateOfMonth }, get dayjs() { return dayjs }, get Field() { return Field }, get Form() { return Form }, computed, reactive, ref, get array() { return array }, get object() { return object }, get RetireModule() { return RetireModule }, TheItemRetire, get RenewableOptionsModule() { return RenewableOptionsModule }, get AccountModule() { return AccountModule }, get IRenewableParameters() { return IRenewableParameters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})